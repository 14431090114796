import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteRegisterPage.css';
import completeIcon from '../assets/successReg-img.png';
import Axios from 'axios';
import moment from 'moment';
import 'moment/locale/th';

const CompleteRegisterPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const {setStep, userData, setUserData, submitData} = useContext(multiStepContext);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [createDate, setCreateDate] = useState(null)
  const [expectedDate, setExpectedDate] = useState(null);

  useEffect(() => {
    const getAddressData = async () => {
      try {
        const response = await Axios.get(apiURL + `getStudentSubmitDate/${userData.citizenId}`);
        setCreateDate(response.data.result.Records[0].createDate)
        const retrievedDate = response.data.result.Records[0].createDate;
        let newDate = moment(retrievedDate).add(10, 'days');
        newDate = moment(newDate).add(543, 'years').format('D MMMM YYYY');
        setExpectedDate(newDate);
      } catch (error) {
        console.error('API call failed:', error);
      }
    };

    getAddressData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderMessage = () => {
    if (!expectedDate) return null;

    switch (userData.simMethod) {
      case 'school':
        return `ท่านสามารถเข้ารับซิมการ์ดได้ ตั้งแต่วันที่ ${expectedDate} เป็นต้นไป`;
      case 'branch':
        return `ท่านสามารถเข้ารับซิมการ์ดได้ ตั้งแต่วันที่ ${expectedDate} เป็นต้นไป`;
      default:
        return `ท่านสามารถกลับมาตรวจสอบผลการลงทะเบียน ตั้งแต่วันที่ ${expectedDate} เป็นต้นไป`;
    }
  };
  
  return (
    <div className="complete-container">
        <div className='complete-regis'>
            ระบบได้รับข้อมูลของท่านแล้ว
            <br/>
            <span style={{ fontSize: '16' }}>You're good to go</span>
        </div>
        <div className="complete-img-container">
          <img src={completeIcon} className={`complete-reg-img ${windowHeight < 650 ? 'hidden-notFound' : 'complete-reg-img'}`}></img>
        </div>
        <div className="complete-text">
          {renderMessage()}
        </div>
        <div className="complete-register-con">
            <button onClick={() => { submitData(); setStep(1); }} className="complete-register-bt">กลับสู่หน้าหลัก</button>
        </div>
    </div>
  )
}

export default CompleteRegisterPage