import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/AlreadyConfirm.css';
import ApproveImg from '../assets/Approved-img.png';

const AlreadyConfirm = () => {
  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="already-container">
        <div className='already-status'>
            ท่านได้ลงทะเบียนเป็นที่เรียบร้อยแล้ว
            <br/>
            <span style={{ fontSize: '16' }}>You've already registered</span>
        </div>
        <div className="already-img-container">
          <img src={ApproveImg} className={`already-img ${windowHeight < 650 ? 'hidden-notFound' : 'already-img'}`}></img>
        </div>
        <div className="notFound-text">
        </div>
        <div className="already-status-con">
            <button onClick={() => setStep(1)} className="complete-status-bt">กลับสู่หน้าหลัก</button>
        </div>
    </div>
  )
}

export default AlreadyConfirm