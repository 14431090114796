import React, { useState, useEffect } from "react";
import FormPage from "./Components/FormPage";

export const multiStepContext = React.createContext();
const StepContext = () => {
    const [currentStep, setStep] = useState(1);
    const [userData, setUserData] = useState({});
    const [finalData, setFinalData] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    function submitData() {
        let newData = { ...userData };
        
        if (newData['phoneNumberOption'] === "ใช่") {
            delete newData['phoneNumber'];
        }
    
        setFinalData(prevFinalData => [...prevFinalData, newData]);
        
        setUserData({});
        setStep(1);
    }

    return (
        <div>
            <multiStepContext.Provider value={{currentStep, setStep, userData, setUserData, finalData, setFinalData, submitData, startTime, setStartTime, endTime, setEndTime}}>
                <FormPage/>
            </multiStepContext.Provider>
        </div>
    );
}

export default StepContext;