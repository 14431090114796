import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/UserInfoPage.css'
import formProcessBar from '../assets/form-status-bar.png';
import Axios from 'axios';

const UserInfoPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { setStep, userData, setUserData } = useContext(multiStepContext);

  useEffect(() => {
    const getStudentData = async () => {
      try {
        const response = await Axios.get(apiURL + `getStudentData/${userData.citizenId}`);
        const studentInfo = response.data.result;
        setUserData({
          ...userData,
          firstname: studentInfo.s_firstname,
          lastname: studentInfo.s_lastname,
          schoolName: studentInfo.school_name,
          schoolId: studentInfo.school_id
        });
      } catch (error) {
        console.error('API call failed:', error);
      }
    };

    getStudentData();
  }, []);

  const handleNextStep = async () => {
    const student = await checkStudent(userData.citizenId);

    if(student.result === "Match"){
      setStep(15);
    }
    else{
      setStep(13);
    }
  }

  const checkStudent = async (nationalId) => {
    try {
      var response = await Axios.post(apiURL+`checkStudentInfo/${nationalId}`, {
        sFirstName: userData.firstname,
        sLastName: userData.lastname
      });
      return response.data

    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };

  const handleFirstNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, firstname: trimmedValue });
  }

  const handleLastNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, lastname: trimmedValue });
  }

  return (
    <div className="userInfo-container">
      <div className="userInfo-process-bar">
        <img src={formProcessBar} className="userInfo-pro-bar" alt="Form Process Bar" />
      </div>
      <div className="userInfo-info">
        โปรดกรอกข้อมูลในแบบฟอร์ม
        <br />
        <span style={{ fontSize: '16' }}>Please indicate the most suitable option</span>
      </div>
      <div className="userInfo-input">
        <div className="userInfo-name">
          <div className="userInfo-fname">
            ชื่อ
            <input type="text"
              className='form-text'
              placeholder='โปรดกรอกชื่อจริง'
              value={userData.firstname || ''}
              readOnly
              onChange={handleFirstNameChange}></input>
          </div>
          <div className="userInfo-lname">
            นามสกุล
            <input type="text"
              className='form-text'
              placeholder='โปรดกรอกนามสกุล'
              value={userData.lastname || ''}
              readOnly
              onChange={handleLastNameChange}></input>
          </div>
        </div>
        <div className="userInfo-id">
          <div className="userInfo-id">
            หมายเลขบัตรประชาชน
            <input
              type="text"
              className='form-id'
              placeholder='โปรดกรอกหมายเลขบัตรประชาชน'
              value={userData.citizenId || ''}
              disabled
              readOnly
            />
          </div>
        </div>
        <div className="userInfo-id">
          <div className="userInfo-id">
            ชื่อโรงเรียน
            <input
              type="text"
              className='form-id'
              value={userData.schoolName || ''}
              disabled
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="form-bt">
        <button onClick={handleNextStep} className="form-next-bt">ต่อไป</button>
      </div>
    </div>
  );
};

export default UserInfoPage;