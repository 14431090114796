import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/NotFoundPage.css';
import failIcon from '../assets/fail-icon.png';
import moment from 'moment';
import 'moment/locale/th';

const NotLivePage = () => {
    const { setStep, startTime, endTime } = useContext(multiStepContext);

    const goToHomePage = () => {
        setStep(1);
    };

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    moment.locale('th');

    const formattedStartTime = startTime ? moment(startTime).format('D MMMM YYYY HH:mm') : '';
    const formattedEndTime = endTime ? moment(endTime).format('D MMMM YYYY HH:mm') : '';

    return (
        <div className="notFound-container">
            <div className='notFound-info'>
                ยังไม่ถึงช่วงเวลาลงทะเบียน <br />
                <span style={{ fontSize: '16' }}>ช่วงเวลาลงทะเบียนเริ่มวันที่ {formattedStartTime} ถึง {formattedEndTime}<br /></span><br />
            </div>
            <div className='notFound-info-mobile'>
                ยังไม่ถึงช่วงเวลาลงทะเบียน<br />
                <span style={{ fontSize: '16' }}>ช่วงเวลาลงทะเบียนเริ่มวันที่ {formattedStartTime} ถึง {formattedEndTime}<br /></span><br />
            </div>
            <div className="notFound-img-container">
                <img src={failIcon} className={`notFound-img ${windowHeight < 650 ? 'hidden-notFound' : 'notFound-img'}`} alt="Fail Icon" />
            </div>
            <div className="notFound-text">
            </div>
            <div className="notFound-register-con">
                <button onClick={goToHomePage} className="notFound-register-bt">ย้อนกลับ</button>
            </div>
        </div>
    )
}

export default NotLivePage;