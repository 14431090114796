import React, { useContext, useState, useEffect } from 'react';
import { CreateInput } from 'thai-address-autocomplete-react';
import { multiStepContext } from '../StepContext';
import '../Styles/SimMethod.css';
import '../Styles/SimAddressDefault.css';
import '../Styles/SimAddressNew.css'
import lineQR from '../assets/qrcode_line.png';
import Axios from 'axios';

const InputThaiAddress = CreateInput();

const SimAddressNew = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [addressNameNew, setAddressName] = useState(userData.addressNameNew || "");
    const [emailNew, setEmail] = useState(userData.emailNew || "");
    const [lineIdNew, setLineId] = useState(userData.lineIdNew || "");
    const [phoneNumberNew, setPhoneNumber] = useState(userData.phoneNumberNew || "");

    const [address, setAddress] = useState({
        district: userData.subDistrictNew || '',
        amphoe: userData.districtNew || '',
        province: userData.provinceNew || '',
        zipcode: userData.zipCodeNew || '',
    });

    const handleChange = (scope) => (value) => {
        setAddress((oldAddr) => ({
            ...oldAddr,
            [scope]: value,
        }));
    };

    const handleSelect = (address) => {
        setAddress(address);
    };

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const closeInfoPopup = () => {
        setIsPopupVisible(false);
    };

    const handleNextStep = () => {
        if (addressNameNew.trim() === "" || phoneNumberNew.trim().length !== 10) {
            alert("กรุณากรอกข้อมูลที่อยู่และเบอร์โทรติดต่อให้ครบถ้วน");
            return;
        }
        setUserData({
            ...userData,
            addressNameNew,
            districtNew: address.amphoe,
            subDistrictNew: address.district,
            provinceNew: address.province,
            zipCodeNew: address.zipcode,
            emailNew,
            lineIdNew,
            phoneNumberNew,
        });
        setStep(11);
    };

    return (
        <>
            <div className="sim-body">
                <div className="sim-container">
                    <div className="sim-area-con">
                        <div className="sim-info">
                            เพิ่มที่อยู่การจัดส่งซิมการ์ด
                            <br />
                            <span style={{ fontSize: '16' }}>SIM card delivery address.</span>
                        </div>
                        <div className="sim-fill-area">
                            <div className="radio-container">
                                <div className='acc-package'>
                                    ที่อยู่/บ้านเลขที่/อาคาร/ถนน/ซอย
                                    <input
                                        type="text"
                                        className='address-name'
                                        value={addressNameNew}
                                        onChange={(e) => setAddressName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-service">
                                        ตำบล
                                        <InputThaiAddress.District
                                            type="text"
                                            className='address-sub-district'
                                            value={address['district']}
                                            onChange={handleChange('district')}
                                            onSelect={handleSelect}
                                            required
                                        />
                                    </div>
                                    <div className="acc-phone">
                                        อำเภอ
                                        <InputThaiAddress.Amphoe
                                            type="text"
                                            className='address-district'
                                            value={address['amphoe']}
                                            onChange={handleChange('amphoe')}
                                            onSelect={handleSelect}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-phone">
                                        จังหวัด
                                        <InputThaiAddress.Province
                                            type="text"
                                            className='address-province'
                                            value={address['province']}
                                            onChange={handleChange('province')}
                                            onSelect={handleSelect}
                                            required
                                        />
                                    </div>
                                    <div className="acc-service">
                                        รหัสไปรษณีย์
                                        <InputThaiAddress.Zipcode
                                            type="text"
                                            className='address-zipcode'
                                            value={address['zipcode']}
                                            onChange={handleChange('zipcode')}
                                            onSelect={handleSelect}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-half">
                                        เบอร์โทรติดต่อ
                                        <input
                                            type="text"
                                            className="address-phone-number"
                                            value={phoneNumberNew}
                                            onChange={(e) => {
                                                const onlyDigits = e.target.value.replace(/\D/g, '');
                                                if (onlyDigits.length <= 10) {
                                                    setPhoneNumber(onlyDigits);
                                                }
                                            }}
                                            maxLength="10"
                                            required
                                        />
                                    </div>
                                    <div className="acc-phone">
                                        อีเมล (ถ้ามี)
                                        <input
                                            type="text"
                                            className='address-email'
                                            value={emailNew}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-half">
                                        Line ID (ถ้ามี)
                                        <input
                                            type="text"
                                            className='address-line-id'
                                            value={lineIdNew}
                                            onChange={(e) => setLineId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='sim-submit-area'>
                                <label>
                                    <a href="#" className="to-service" onClick={togglePopup}>ติดต่อเจ้าหน้าที่</a>
                                </label>
                                <div className='sim-button'>
                                    <button onClick={() => setStep(30)} className="form-back-bt">
                                        ย้อนกลับ
                                    </button>
                                    <button onClick={handleNextStep} className="authen-next-button">
                                        ต่อไป
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-window">
                        <div className="popup-header">
                            <span className="popup-title">ติดต่อเจ้าหน้าที่</span>
                            <button className="popup-close-btn" onClick={closeInfoPopup}>
                                &times;
                            </button>
                        </div>
                        <div className="popup-content" style={{ paddingTop: '0px' }}>
                            <ul className='popup-content-text'>
                                <li>
                                    ช่องทางการติดต่อผู้ให้บริการ Infinite sim บนโครงข่ายโทรคมนาคมแห่งชาติ โครงการอินเตอร์เน็ตฟรีสำหรับผู้พิการและนักเรียนยากจน
                                    <span style={{ marginLeft: '5px' }}>
                                        <a href="https://lin.ee/EgE32QQ" target="_blank" rel="noopener noreferrer" className="popup-link">
                                            https://lin.ee/EgE32QQ
                                        </a>
                                    </span>
                                </li>
                                <li>Line : @netfree_infinite</li>
                            </ul>
                            <img src={lineQR} className='popup-line-qr' alt="QR Code" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SimAddressNew;