import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteRegisterPage.css';
import '../Styles/AdvicePage.css';
import completeIcon from '../assets/successReg-img.png';

const AdvicePage = () => {
    const { setStep, userData, setUserData } = useContext(multiStepContext);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="complete-container">
            <div className='complete-regis'>
                คำแนะนำ
                <br />
                <span style={{ fontSize: '16' }}>Advice</span>
            </div>
            <div className="complete-img-container">
                <img src={completeIcon} className={`complete-reg-img ${windowHeight < 650 ? 'hidden-notFound' : 'complete-reg-img'}`}></img>
            </div>
            <div className='advice-container'>
                <strong>ย้ายค่ายเบอร์เดิม</strong>
                <ol className='advice-text'>
                    <li>
                        กด * 151 * เลขบัตรประชาชน # โทรออก เพื่อรับรหัสยืนยันจากค่ายเดิม
                    </li>
                    <li>
                        ชำระยอดคงค้างของค่ายเดิม ให้เรียบร้อย
                    </li>
                    <li>
                        ส่งรหัสยืนยันที่ได้รับทางSMS จากค่ายเดิม + สำเนาบัตรประชาชนเจ้าของเลขหมาย ส่งทางไลน์ Line : @netfree_infinite  หรือกดส่งตามลิ้งค์นี้ได้เลยครับ
                        <span style={{ marginLeft: '5px' }}>
                            <a href="https://lin.ee/Z0EMZL0h" target="_blank" rel="noopener noreferrer" className="popup-link">
                                https://lin.ee/Z0EMZL0h
                            </a>
                        </span>
                    </li>
                    <li>
                        รอการอนุมัติการย้ายค่าย และนำหมายเลขเดิม กลับมาลงทะเบียนขอรับสิทธิ์
                    </li>
                    <li>
                        ทางบริษัทฯ จะจัดส่งซิม โดยมีระยะประมาณ10 วัน ไปให้ตามชื่อ ที่อยู่ ตามที่ท่านได้ลงทะเบียนผ่านช่องทางรับสิทธิ์นี้เท่านั้น
                    </li>
                </ol>
            </div>
            <div className="advice-button-section">
                <button onClick={() => setStep(15)} className="form-back-bt">
                    ย้อนกลับ
                </button>
                <button onClick={() => {
                    setStep(1)
                    setUserData(null)
                }}
                    className="complete-register-bt">
                    กลับสู่หน้าหลัก
                </button>
            </div>
        </div>
    )
}

export default AdvicePage