import './App.css';
import FormPage from './Components/FormPage';
import PortalPage from './Components/Portalpage';

function App() {
  return (
    <div>
      <FormPage/>
    </div>
  );
}

export default App;
