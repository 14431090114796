import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/WaitingPage.css';
import waitingIcon from '../assets/waiting.jpg';

const WaitingPage = () => {

  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const goToHomePage = () => {
    setStep(1);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="waiting-container">
        <div className='waiting-info'>
            ขณะนี้ข้อมูลของท่านอยู่ระหว่างดําเนินการ
            <br/>
            <span style={{ fontSize: '16' }}>We Are Currently Processing Your Information</span>
        </div>
        <div className="waiting-img-container">
          <img src={waitingIcon} className={`waiting-img ${windowHeight < 650 ? 'hidden-notFound' : 'waiting-img'}`}></img>
        </div>
        <div className="waiting-text">
        </div>
        <div className="waiting-register-con">
            <button onClick={goToHomePage} className="waiting-register-bt">ย้อนกลับ</button>
        </div>
    </div>
  )
}

export default WaitingPage;