import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/NotFoundPage.css';
import failIcon from '../assets/fail-icon.png';

const NotFoundStatus = () => {

  const {setStep, userData, setUserData} = useContext(multiStepContext);

  const goToHomePage = () => {
    setStep(1);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="notFound-container">
        <div className='notFound-info'>
            ท่านยังไม่ได้ลงทะเบียนในโครงการ
            <br/>
            <span style={{ fontSize: '16' }}>You are not Eligible</span>
        </div>
        <div className='notFound-info-mobile'>
            ท่านยังไม่ได้ลงทะเบียนในโครงการ
            <br/>
            <span style={{ fontSize: '16' }}>You are not Eligible</span>
        </div>
        <div className="notFound-img-container">
          <img src={failIcon} className={`notFound-img ${windowHeight < 650 ? 'hidden-notFound' : 'notFound-img'}`}></img>
        </div>
        <div className="notFound-text">
        </div>
        <div className="notFound-register-con">
            <button onClick={goToHomePage} className="notFound-register-bt">ย้อนกลับ</button>
        </div>
    </div>
  )
}

export default NotFoundStatus;