import React, { useContext, useState } from 'react';
import { multiStepContext } from '../StepContext';
import legalProcessBar from '../assets/legal-process-bar.png';
import '../Styles/LegalPage.css';
import Axios from 'axios';

const LegalPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const {setStep, userData, setUserData} = useContext(multiStepContext);
  const nationalId = userData.citizenId;

  const handleNextStep = async () => {

    const student = await checkParent(nationalId, userData);
    const parent = await checkStudent(nationalId);

    if(student.result === "Match" && parent.result === "Match"){
      setStep(15);
    }
    else{
      setStep(13);
    }
  }

  const checkStudent = async (nationalId) => {
    try {
      var response = await Axios.post(apiURL+`checkStudentInfo/${nationalId}`, {
        sFirstName: userData['firstname'],
        sLastName: userData['lastname']
      });
      return response.data

    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };

  const checkParent = async (nationalId, userData) => {
    try {
      var response = await Axios.post(apiURL+`checkParentInfo/${nationalId}`, {
        PFirstName: userData.legalFirstname,
        PLastName: userData.legalLastname,
        PNationalId: userData.legalId,
        relationship: userData.legalRelation
      });
      return response.data

    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };
 
  const handleLegalFirstNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, legalFirstname: trimmedValue });
  }
  
  const handleLegalLastNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, legalLastname: trimmedValue });
  }

  const handleLegalIdChange = (e) => {
    let numericValue = e.target.value.replace(/\D/g, '').trim(); 
    if (numericValue.length > 13) {
      numericValue = numericValue.slice(0, 13);
    }
    setUserData({ ...userData, legalId: numericValue });
  }

  const handleLegalRelationChange = (e) => {
    const selectedOptionValue = e.target.value;
    setUserData({ ...userData, legalRelation: selectedOptionValue });
  }
  
  return (
    <div className="legal-container">
        <div className="legal-process-bar"> 
              <img src={legalProcessBar} className="legal-pro-bar"></img>
        </div>
        <div className="legal-info">
              สําหรับกรอกข้อมูลผู้ดูแลตามกฎหมาย
              <br/>
              <span style={{ fontSize: '16' }}>For legal / lawful guardian information</span> 
        </div>
        <div className='legal-input'>
           <div className="legal-name">
              <div className="legal-fname">
                   ชื่อ
                   <input
                      className="legal-text"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['legalFirstname']}
                      onChange={handleLegalFirstNameChange}
                    />
              </div>
              <div className="legal-lname">
                นามสกุล
                    <input
                      className="legal-text"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['legalLastname']}
                      onChange={handleLegalLastNameChange}
                    />
              </div>
            </div>
          <div className="legal-id">
              หมายเลขบัตรประชาชน
                    <input
                      className="legal-idNum"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['legalId']}
                      onChange={handleLegalIdChange}
                    />
          </div>
          <div className="legal-id">
            เกี่ยวข้องเป็น
            <select
              className="legal-idNum"
              value={userData['legalRelation']}
              onChange={handleLegalRelationChange}
            >
              <option value="">โปรดเลือกตัวเลือก</option>
              <option value="บิดา">บิดา</option>
              <option value="มารดา">มารดา</option>
              <option value="ปู่">ปู่</option>
              <option value="ย่า">ย่า</option>
              <option value="ตา">ตา</option>
              <option value="ยาย">ยาย</option>
              <option value="ลุง">ลุง</option>
              <option value="ป้า">ป้า</option>
              <option value="น้า">น้า</option>
              <option value="อา">อา</option>
              <option value="ทวด">ทวด</option>
              <option value="พี่ชาย">พี่ชาย</option>
              <option value="พี่สาว">พี่สาว</option>
              <option value="น้องชาย">น้องชาย</option>
              <option value="น้องสาว">น้องสาว</option>
              <option value="พ่อเลี้ยง">พ่อเลี้ยง</option>
              <option value="แม่เลี้ยง">แม่เลี้ยง</option>
              <option value="พ่อบุญธรรม">พ่อบุญธรรม</option>
              <option value="แม่บุญธรรม">แม่บุญธรรม</option>
              <option value="ผู้ดูแล">ผู้ดูแล</option>
            </select>
          </div>
        </div>
        <div className="form-bt">
            <button onClick={() => setStep(3)} className="form-back-bt">ย้อนกลับ</button>
            <button onClick={ handleNextStep } className="form-next-bt">ต่อไป</button>
        </div>
    </div>
  );
};

export default LegalPage;