import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/RegisterCheckPage.css'
import lastStep from '../assets/last-step.png';
import Axios from 'axios';

const RegisterCheckPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);
  const [options, setOptions] = useState([]);
  const branchId = parseInt(userData.branchId);


  const accBackStep = () => {
    if (userData.phoneNumberOption === "ไม่ใช่") {
      setStep(5);
    } else {
      if (userData.simMethod === "school") {
        setStep(30)
      } else if (userData.simMethod === "branch") {
        setStep(10);
      }
    }
  };

  const getOperator = async () => {
    try {
      const response = await Axios.get(apiURL + 'getAllOperator');
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  useEffect(() => {
    getOperator();
  }, [userData]);

  const renderBrandOption = () => {
    const selectedOption = options.find(option => option.id === parseInt(userData.brandOption));
    return selectedOption ? selectedOption.company_name : 'Unknown';
  };

  const renderPhoneNumber = () => {
    if (userData["phoneNumberOption"] === "ไม่ใช่") {
      return userData["phoneNumber"]
    }
    else {
      return "ท่านประสงค์รับซิมการ์ดใหม่"
    }
  }

  const sentDatatoAPI = async () => {
    try {
      const response = await Axios.post(apiURL + `saveStudentData`, {
        StNationalId: userData['citizenId'],
        purpose: userData['phoneNumberOption'],
        phoneNumber: userData['phoneNumber'],
        operator: parseInt(userData.brandOption),
        status: "submit"
      });
      updateStatus(userData.citizenId);
      setStep(6);
    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };

  const updateStatus = async (statusId) => {
    if (userData.simMethod === 'branch') {
      const response = await Axios.put(apiURL + `changeStudentStatus/${statusId}`, {
        branchId: branchId
      });
    } else if (userData.simMethod === 'school') {
      const response = await Axios.put(apiURL + `changeStudentStatus/${statusId}`, {
        schoolId: userData.schoolId
      });
    }
  }

  const handleLocation = () => {
    if (userData.phoneNumberOption === "ใช่") {
      if (userData.simMethod === 'school') {
        return (
          <div className='acc-service'>
            รับซิมการ์ดที่โรงเรียน
            <input type="text" className='reg-acc-text' value={userData.schoolName} readOnly></input>
          </div>
        );
      } else if (userData.simMethod === "branch") {
        return (
          <div className='acc-service'>
            รับซิมการ์ดที่ทำการไปรษณีย์
            <input type="text" className='reg-acc-text' value={userData.branchName} readOnly></input>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <>
      {
        <div className="reg-acc-body">
          <div className="reg-acc-container">
            <div className="reg-acc-area-con">
              <div className="reg-acc-process-bar">
                <img src={lastStep} className="last-step-bar" alt="OTP Process Bar" />
              </div>
              <div className="reg-acc-info">
                โปรดตรวจสอบความถูกต้อง
                <br />
                <span style={{ fontSize: '16' }}>Please check the accuracy</span>
              </div>
              <div className="reg-acc-service-detail">
                <div className="acc-service">
                  ชื่อ
                  <input type="text" className='reg-acc-text' value={userData['firstname']} readOnly></input>
                </div>
                <div className="reg-acc-phone">
                  นามสกุล
                  <input type="text" className='reg-acc-text' value={userData['lastname']} readOnly></input>
                </div>
              </div>
              <div className='reg-acc-package'>
                หมายเลขบัตรประชาชน
                <input type="text" className='reg-status-id' value={userData['citizenId']} readOnly></input>
              </div>
              <div className="reg-acc-service-detail">
                <div className="acc-service">
                  เครือข่าย
                  <input type="text" className='reg-acc-text' value={renderBrandOption()} readOnly></input>
                </div>
                <div className="reg-acc-phone">
                  หมายเลขโทรศัพท์
                  <input type="text" className='reg-acc-text' value={renderPhoneNumber()} readOnly></input>
                </div>
              </div>
              <div className="reg-acc-service-detail">
                {handleLocation()}
              </div>
              <div className="reg-acc-bt">
                <button onClick={accBackStep} className="reg-acc-back-bt">ย้อนกลับ</button>
                <button onClick={() => { sentDatatoAPI(); }} className="reg-acc-next-bt">ยืนยันข้อมูล</button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default RegisterCheckPage;