import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import lineQR from '../assets/qrcode_line.png'
import '../Styles/DetailPage.css';
import Axios from 'axios';

const DetailPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, finalData, setUserData } = useContext(multiStepContext);
    const statusId = userData.statusId;
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const [type, setType] = useState("");

    const getServiceAndNum = async (statusId) => {
        const response = await Axios.get(apiURL + `getStudentOperationRecord/${statusId}`);
        setType(response.data);
    }

    useEffect(() => {
        if (statusId) {
            getServiceAndNum(statusId);
        }
    }, [statusId]);

    const serviceDetail = type && type.OpInfo.studentInfo[0].operators.company_name;
    const numberDetail = type && type.OpInfo.studentInfo[0].phoneNumber;
    const imgDetail = type && type.OpInfo.studentInfo[0].packages.Banners_student;
    const purposeNumber = type && type.purpose
    const serviceID = type && type.OpInfo.studentInfo[0].operators.id;

    const renderPurpose = () => {
        if (numberDetail) { return numberDetail; }
        else { return 'รับซิมการ์ดใหม่' }
    };

    const handleDetailStep = () => {
        if (purposeNumber === "ไม่ใช่") {
            setUserData({ ...userData, purpose: purposeNumber });
            setStep(11);
        } else {
            setStep(11);
        }
        setUserData({ ...userData, serviceID: serviceID })
    };

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const closeInfoPopup = () => {
        setIsPopupVisible(false);
    };
    
    return (
        <>
            <div className="detail-body">
                <div className="detail-container">
                    <div className="detail-area-con">
                        <div className="detail-info">
                            ข้อมูลหมายเลขของท่าน
                            <br />
                            <span style={{ fontSize: '16' }}>Your Number Details</span>
                        </div>
                        <div className="detail-fill-area">
                            <div className="detail-brand-phone">
                                <div className="detail-brand">
                                    เครือข่าย
                                    <input type="text" className='detail-text' value={serviceDetail} readOnly></input>
                                </div>
                                <div className="detail-phone">
                                    หมายเลขโทรศัพท์
                                    <input type="text" className='detail-text' value={renderPurpose()} readOnly></input>
                                </div>
                            </div>
                            <div className="center-img">
                                <img src={imgDetail} className={`detail-img ${windowHeight < 650 ? 'detail-small' : 'detail-img'}`} alt="Brand Package" />
                            </div>
                            <div className='detail-submit-area'>
                                <label>
                                    <a href="#" className="to-service" onClick={togglePopup}>ติดต่อเจ้าหน้าที่</a>
                                </label>
                                <button onClick={handleDetailStep} className="to-location-button">
                                    ยืนยันข้อมูล
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-window">
                        <div className="popup-header">
                            <span className="popup-title">ติดต่อเจ้าหน้าที่</span>
                            <button className="popup-close-btn" onClick={closeInfoPopup}>
                                &times;
                            </button>
                        </div>
                        <div className="popup-content" style={{ paddingTop: '0px' }}>
                            <ul className='popup-content-text'>
                                <li>
                                    ช่องทางการติดต่อผู้ให้บริการ Infinite sim บนโครงข่ายโทรคมนาคมแห่งชาติ โครงการอินเตอร์เน็ตฟรีสำหรับผู้พิการและนักเรียนยากจน
                                    <span style={{ marginLeft: '5px' }}>
                                        <a href="https://lin.ee/EgE32QQ" target="_blank" rel="noopener noreferrer" className="popup-link">
                                            https://lin.ee/EgE32QQ
                                        </a>
                                    </span>
                                </li>
                                <li>Line : @netfree_infinite</li>
                            </ul>
                            <img src={lineQR} className='popup-line-qr' />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default DetailPage;