import React, { useState, useEffect } from 'react';
import sideimg from '../assets/sideimg.png';
import '../Styles/SidebarImg.css';

const SidebarImg = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <img 
      src={sideimg} 
      alt="sideimg" 
      className={`sidebar-img ${windowHeight < 700 ? 'hidden' : 'sidebar-img'}`}
    />
  );
}

export default SidebarImg;